#topmostnav,
#mainNav {
  background-color: $med-grey;
}
#mainNav2 {
  background-color: $med-dark-grey;
}

#mainNav {
  border-bottom: 2px solid $med-dark-grey;
  padding-bottom: 0;
}

#mainNav a.nav-link,
#mainNav button.nav-link-btn {
  font-size: 11pt;
  font-weight: bold;
  color: #0074cc;
  text-transform: uppercase;
  margin-top: 6px;
  border-radius: 6px 6px 0 0;
  background-color: #e6e6e6;
  margin-left: 3px;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#mainNav button.nav-link-btn {
  border: none;
}

#mainNav a.active {
  background-color: #999999;
  color: #ffffff;
  text-decoration: none;
  border-radius: 6px 6px 0 0;
}

.navbar-brand img {
  max-height: 80px;
}

#userMenu {
  font-size: 0.9rem;
}

#userMenu,
#userMenu a {
  color: #333333;
}

#userMenu a {
  text-decoration: none;
}

a#signoutLink {
  color: #00b3d8;
}

@media only screen and (max-width: 1250px) {
  .searchCommentArea {
    position: absolute;
    top: -30px !important;
    right: 15px !important;
    width: 300px !important;
    > .input-group {
      width: 200px !important;
    }
  }
}

