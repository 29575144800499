.btn-warning {
  background-color: #faa732;
  border-color: #d08002;
}

button.btn-delete {
  background-color: #da4f49;
  border: #dc3c35;

}
button.btn-delete:hover {
  background-color: #b91f19;
}

button.secondary-nav {
  background-color: #5c636a;
  color: #efffff;
}

button.sub-navigation-dropdown {
  background-color: #5c636a;
  color: #ffffff;
}

button.btn-yellow {
  background-color: $cf-yellow;
  border-color: $cf-yellow;

  :hover {
    background-color: $yellow;
    border-color: $yellow;
  }
}

button.btn-admin-dropdown {
  color: white;
}

button.btn-admin-dropdown:hover {
  background-color: #006d91;
  color: white;
}

button.btn-red {
    background-color:#da4f49;
    :hover {

    }
}

Button.button-round {
    border-radius: 0.25rem;
}

Button.btn-bell {
    background-color: $med-grey;
    border-color: transparent;
    color: #313131;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

Button.btn-bell:hover {
    background-color: $med-grey;
    border-color: transparent;
}

Button.btn-bell:active {
    background-color: $med-grey;
    border-color: transparent;
}

button.btn-inv-context {
    color: white;
}

button.btn-inv-context:hover {
    background-color: #006d91;
    color: white;
}

button.btn-blue {
    background-color: #5bc0de;
    border-color: #00b3d8;
}
button.btn-blue:hover {
    background-color: #48afce;
}

button.btn-comment-none{
    background-color: $med-grey;
    border-color: $med-dark-grey;
    :hover {
        background-color: $med-dark-grey;
        border-color: $med-grey;
    }
}
.bg-cf-yellow {
  background-color: #FCC016 !important;
}
.bg-orange {
    background-color: #F47D22 !important;
}
.bg-cf-red {
  background-color: #F47D22 !important;
  border-color: #F47D22 !important;
}
.btn.btn-tb-active {
  filter: brightness(75%);
}

.btn.btn-tb-0 {
    background-color: #fff !important;
    border-color: #fff !important;
    color: #000;
}
.btn.btn-tb-1 {
  background-color: $btn-color-1;
  border-color: $btn-color-1;
  color: #000;
}

.btn.btn-tb-2 {
  background-color: $btn-color-2;
  border-color: $btn-color-2;
  color: #000;
}

.btn.btn-tb-3 {
  background-color: $btn-color-3;
  border-color: $btn-color-3;
  color: #000;
}

.btn.btn-tb-4 {
  background-color: $btn-color-4;
  border-color: $btn-color-4;
  color: #000;
}

.btn.btn-tb-5 {
  background-color: $btn-color-5;
  border-color: $btn-color-5;
  color: #000;
}

.btn.btn-tb-6 {
  background-color: $btn-color-6;
  border-color: $btn-color-6;
  color: #FFF;
}

.btn.btn-tb-7 {
  background-color: $btn-color-7;
  border-color: $btn-color-7;
  color: #000;
}

.btn.btn-tb-8 {
  background-color: $btn-color-8;
  border-color: $btn-color-8;
  color: #FFFFFF;
}

.btn.btn-tb-9 {
  background-color: $btn-color-9;
  border-color: $btn-color-9;
  color: #FFFFFF;
}

.btn.btn-serial-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.1rem;
  margin: 0;
  background-color: transparent;
  border-color: transparent;
  float: right;
}

.btn.btn-circle {
  position: fixed;
  z-index: 10;
}

.build-btn {
    display: inline-block;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    //padding: 0.375rem
}