.primarySidebar {
  position: fixed;
  top: var(--header-height); /*0;*/
  left: 0;
  height: calc(100vh - var(--header-height));

  /*height: calc(100vh - var(--alert-height));*/
  width: var(--sidebar-width);
  background: #f8f9fa;
  z-index: 999;
}
.alertBox {
  position: fixed;
  bottom: 0;
  left: 0;
  width: var(--sidebar-width);
  height: var(--alert-height);
  z-index: 10000;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  background-color: #555;
}
.alert:disabled {
  height: 0px; /*calc(10%);*/
}

.infoAlert {
  margin-top: 1rem;
}

.secondarySidebar {
  position: fixed;
  top: var(--header-height);
  right: 0;
  height: calc(100vh - var(--header-height));
  width: var(--secondary-sidebar-width);
  background: #f8f9fa;
  z-index: 997;
}

.secondarySidebar > div {
  margin: 0 !important;
}

.primarySidebar,
.secondarySidebar,
.alertBox {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.primarySidebar::-webkit-scrollbar,
.secondarySidebar::-webkit-scrollbar,
.alertBox::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100%);
  height: var(--header-height);
  background: #f8f9fa;
  z-index: 998;
}

@media screen and (max-width: 1200px) {
  .toolbar {
    font-size: 0.7rem;
  }

  .toolbar label {
    font-size: 0.7rem;
  }
}

.svgTxt {
  font-family: monospace;
}

.disclaimerTxt {
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
}

.svgTxt,
.disclaimerTxt,
.unSelectable {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.controlPoint,
.controlPointActive {
  cursor: pointer;
  fill: #fff;
  stroke: #000;
}

.controlPoint:hover,
.controlPointActive {
  fill: blue;
  stroke: blue;
}
.cursor{
  cursor: pointer;
}
