.mainBody {
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  min-height: 1000px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

/*change these to line up with reactstrap classnames and such*/
table,
label,
.control-label,
.help-block,
.checkbox,
.radio,
.form-control,
.btn,
.dropdown-menu,
.navbar .dropdown-menu > li > a,
.navbar .dropdown-menu > li > a:focus {
  font-size: 16px;
}

.bg-test {
  background-color: #ffc000;
}

.navbar,
.btn-group-sm > .btn,
.btn-sm {
  font-size: 14px;
}

.buttonGroupSpacing {
  margin-right: 5px;
}

.verticalLine {
  border-left: thin solid black;
}

.rowSpacing {
  padding-top: 15px;
}

.final-tab {
  border-bottom: none;
}

h2.page-title {
  font-weight: 500;
  font-size: 20pt;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

span.filter-toggle {
  cursor: pointer;
}

input,
button,
select,
textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

.filter,
.sort-header {
  cursor: pointer;
}

/*Navbar Styling*/
.cf-sidebar {
  background-color: #e6e6e6;
  color: #fff !important;
  font-size: 11pt;
  font-weight: bold;
  margin-top: 6px;
  padding-bottom: 8px;
  padding-top: 8px;
}

a.cf-sidebar {
  cursor: pointer;
}

a.active.nav-link.cf-sidebar,
a:hover.active.nav-link.cf-sidebar,
a:focus.active.nav-link.cf-sidebar {
  background-color: #999999 !important;
  color: #ffffff !important;
  text-decoration: none;
  /*border-radius: 10px 10px 0 0;*/
}

.activeBlue {
  background-color: lightgray !important;
}

.boldData {
  font-weight: bold !important;
}
/*Tables*/
table.details-table {
  width: 100%;
}

table.details-table tbody tr td.caption {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 11pt;
  font-weight: bold;
  padding: 2px 2px 2px 5px;
  text-align: left;
}

table.details-table tbody tr td.val {
  background-color: #fff;
  border: 1px solid #999;
  color: #333;
  font-size: 11pt;
  padding-left: 3px;
  text-align: left;
}

.tr-success {
  background-color: #dff0d8;
}

/*Colors*/
.cf-primary {
  background-color: #0074cc;
  border-color: #0074cc;
  color: #ffffff;
}

/*.cf-success {
  background-color: #5bb75b !important;
  border-color: #5bb75b !important;
  color: #ffffff !important;
}
*/
.cf-info {
  background-color: #5bc0de;
  border-color: #5bc0de;
  color: #ffffff;
}

.cf-warning {
  background-color: #faa732;
  border-color: #faa732;
  color: #ffffff;
}

.cf-danger {
  background-color: #da4f49;
  border-color: #da4f49;
  color: #ffffff;
}

.cf-primary-outline {
  background-color: transparent !important;
  border-color: #0074cc !important;
  color: #0074cc;
  /*white-space: nowrap !important;
    padding: 5px 8px;*/
}

.cf-success-outline {
  background-color: transparent !important;
  border-color: #5bb75b !important;
  color: #5bb75b;
}

.cf-info-outline {
  background-color: transparent !important;
  border-color: #5bc0de !important;
  color: #5bc0de;
}

.cf-warning-outline {
  background-color: transparent !important;
  border-color: #faa732 !important;
  color: #faa732;
}

.cf-danger-outline {
  background-color: transparent !important;
  border-color: #da4f49 !important;
  color: #da4f49;
}

.cf-bw-outline {
  color: #000000 !important;
  background-color: transparent !important;
  border-color: #000000 !important;
}

.cf-grey {
  color: #ffffff !important;
  background-color: #999999 !important;
  border-color: #000000 !important;
  border-style: none !important;
}

.cf1 {
  background-color: #aeaaaa !important;
  border-color: #aaaaaa !important;
  color: #000000 !important;
}

.cf2 {
  background-color: #fff2cc !important;
  border-color: #fff2cc !important;
  color: #000000 !important;
}

.cf3 {
  background-color: #ffe599 !important;
  border-color: #ffe599 !important;
  color: #000000 !important;
}

.cf4 {
  background-color: #ffd966 !important;
  border-color: #ffd966 !important;
  color: #000000 !important;
}

.cf5 {
  background-color: #ffc000 !important;
  border-color: #ffc000 !important;
  color: #000000 !important;
}

.cf6 {
  background-color: #92d050 !important;
  border-color: #92d050 !important;
  color: #000000 !important;
}

.cf7 {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #ffffff;
}

.cf8 {
  background-color: #767171 !important;
  border-color: #767171 !important;
  color: #ffffff;
}

div.dash-panel {
  font-family: Verdana;
  border-radius: 10px;
  cursor: pointer;
  border: solid 2px #666666;
  text-align: center;
  /* background-image: linear-gradient(#FFFFFF, #c0ced8); */
  background-color: #dfe7ec;
  min-height: 160px;
  min-width: 160px;
}

@media all and (-ms-high-contrast: none) {
  .dashboardIcon {
    margin-top: 40px;
  }
  /*  .dash-panel:hover .title,
  .dash-panel:hover .count {
    font-size: 1em;
  } */

  div.dash-panel > div.col {
    padding-top: 30px;
  }
}

div.dash-panel > div {
  margin: auto;
}

div.dash-panel:hover {
  /* background-image: none; */
  background-color: #6e8ea6;
  border: solid 2px #274f96;
}

div.dash-panel:hover .title,
div.dash-panel:hover .count,
div.dash-panel:hover .icon-col {
  color: #ffffff;
}

div.dash-panel div.icon-col {
  color: #00b3d8;
}

div.dash-panel .title {
  font-weight: bold;
  font-size: 1.5em;
  color: #333333;
}

.admin-dropdown {
  padding-bottom: 0 !important;
  background-color: #e6e6e6;
  font-size: 11pt;
  font-weight: bold;
  color: #00b3d8;
  text-transform: uppercase;
}

button.btn-admin-dropdown {
  color: white;
}

button.btn-admin-dropdown:hover {
  background-color: #006d91;
  color: white;
}

@media only screen and (max-width: 1080px) {
  div.dash-panel .title {
    font-size: 1.25em !important;
  }
}

@media only screen and (max-width: 980px) {
  div.dash-panel .title {
    font-size: 1.15em !important;
  }
}

@media only screen and (max-width: 800px) {
  div.dash-panel .title {
    font-size: 1em !important;
  }
}

@media only screen and (max-width: 800px) {
  div.dash-panel .dashboardIcon {
    font-size: 2.5em !important;
  }
}

@media only screen and (max-width: 700px) {
  div.dash-panel .title {
    font-size: 0.8em !important;
  }
}

@media only screen and (max-width: 700px) {
  div.dash-panel .dashboardIcon {
    font-size: 1.75em !important;
  }
}

@media only screen and (max-width: 600px) {
  div.dash-panel .title {
    font-size: 0.75em !important;
  }
}

@media only screen and (max-width: 600px) {
  div.dash-panel .dashboardIcon {
    font-size: 1.65em !important;
  }
}

@media only screen and (max-width: 575px) {
  div.dash-panel .title {
    font-size: 1.15em !important;
  }
}

@media only screen and (max-width: 575px) {
  div.dash-panel .dashboardIcon {
    font-size: 2.5em !important;
  }
}

div.dash-panel .count {
  font-weight: bold;
  font-size: 42pt;
  color: #6e8ea6;
  clear: both;
}

a.no-decoration {
  text-decoration: none !important;
}

label {
  margin-bottom: 2px;
}

.icon-sidebar {
  font-size: 2em;
}
.active.icon-link {
  background-color: #5bb75b !important;
}

.icon-top {
  margin-top: 10px !important;
  align-items: stretch !important;
  justify-content: center !important;
  text-align: center !important;
}
.icon-progress {
  font-size: 3em;
  margin: 10px;
}

.icon-border {
  border: 1px solid rgb(119, 119, 119);
}
.floorplan-image {
  width: 100%;
}

.number-arrows {
  opacity: 1;
  width: 50px;
  color: #00b3d8;
  float: right;
}
.label-full {
  width: 100%;
}

.quill-toolbar-background {
  background-color: #fff !important; 
}

.small-text {
    font-size: small !important;
}

.dark-blue-text {
  color: #0074cc !important;
}