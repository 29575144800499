.new-element {
  //box-shadow: 0px -1px 28px -6px #0275d8;
  border-color: #0275d8;
}


.build-step-card {
  background-color: #fdfdfd;
}

.build-steps-drag:hover {
  cursor: pointer;
}