.form-col-even {
    background-color: $pale-yellow;
}

.form-col-even-quote {
    background-color: $pale-blue;
}

.owner-img > img {
    height: 80px;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid grey;
    border-radius: 16px;
    padding: .5rem;
}

textarea.comment-textarea {
    height: 120px;
    margin-bottom: 10px;
}