div.image-frame {
  border: solid 1px #999999;
  padding: 10px;
  margin: 8px;
  background-color: #d9d9d9;
  float: left;
  width: 275px;
}

div.image-frame img {
  cursor: pointer;
}

span#wholesaleDiscountPctDisplay {
  font-size: 9pt;
}

.del-web-img {
  cursor: pointer;
  margin-top: -9px;
  margin-right: -5px;
  float: right !important;
}
.mark-web-img {
  cursor: pointer;
  margin-top: -9px;
  margin-right: 2px;
  float: right !important;
}

.web-img-box {
  cursor: pointer;
  border: solid 1px #999999;
  padding: 10px;
  margin: 8px;
  background-color: #ffffff;
  display: block;
  max-height: 300px;
  box-sizing: border-box;
  text-align: center;
}

.deactivate-inv-img {
  cursor: pointer;
  margin-top: -9px;
  margin-right: -5px;
  float: right !important;
}

.inv-img {
  border: solid 1px #999999;
  padding: 0;
  margin: 0;
  margin-right: 4px;
  background-color: #d9d9d9;
}

div.inv-image-frame {
  border: solid 1px #999999;
  padding: 8px;
  margin: 8px;
  display: inline-block;
  background-color: #d9d9d9;
}

.upload-inventory-image {
  background-repeat: no-repeat;
  width: 140px;
  height: 140px;
  background-size: 135px;
}
.inv0 { //green
    background-color: #B1CB36;
    color: #000;
    border-color: #000;
}
.inv1 {//green
    background-color: #92D14F;
    color: #000;
    border-color: #000;
}
.bold-text{
    font-weight: bold;
}
.desc-text{
    font-size: 10pt;
    font-style: italic
}
.pnl-green {
  color: #8EC44C;
}
.pnl-red {
  color: #F47D22;
}
.pnl-textbox {
  width: 50%;
  background-color: #FFF !important;
  border: solid 1px #000;
  text-align: right;
}