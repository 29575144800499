 /* Common, default styles for the notification box */
.s-alert-box,
.s-alert-box * {
    box-sizing: border-box;
}

.s-alert-box {
    position: fixed;
    background: rgba(42, 45, 50, 0.85);
    padding: 22px;
    line-height: 1.4;
    z-index: 1070 !important;
    pointer-events: none;
    color: rgba(250, 251, 255, 0.95);
    font-size: 100%;
    font-family: "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
    max-width: 300px;
    -webkit-transition: top 0.4s, bottom 0.4s;
    transition: top 0.4s, bottom 0.4s;
}

    .s-alert-box.s-alert-show {
        pointer-events: auto;
    }

    .s-alert-box a {
        color: inherit;
        opacity: 0.7;
        font-weight: 700;
    }

        .s-alert-box a:hover,
        .s-alert-box a:focus {
            opacity: 1;
        }

    .s-alert-box p {
        margin: 0;
    }

    .s-alert-box.s-alert-show,
    .s-alert-box.s-alert-visible {
        pointer-events: auto;
    }

.s-alert-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4px;
    top: 4px;
    overflow: hidden;
    text-indent: 100%;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

    .s-alert-close:hover,
    .s-alert-close:focus {
        outline: none;
    }

    .s-alert-close::before,
    .s-alert-close::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 60%;
        top: 50%;
        left: 50%;
        background: #fff;
    }

    .s-alert-close:hover::before,
    .s-alert-close:hover::after {
        background: #fff;
    }

    .s-alert-close::before {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
    }

    .s-alert-close::after {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
    }

/* positions */

.s-alert-bottom-left {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 30px;
}

.s-alert-top-left {
    top: 30px;
    right: auto;
    bottom: auto;
    left: 30px;
}

.s-alert-top-right {
    top: 30px;
    right: 30px;
    bottom: auto;
    left: auto;
}

.s-alert-bottom-right {
    /*default*/
    top: auto;
    right: 30px;
    bottom: 30px;
    left: auto;
}

.s-alert-bottom {
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
}

.s-alert-top {
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
}

/* conditions */

.s-alert-info {
    background: #78C0E0;
    color: black;
}

.s-alert-success {
    background: #06AA6E;
    color: #fff;
}

.s-alert-warning {
    background: #FFE072;
    color: black;
}

.s-alert-error {
    background: #B74242;
    color: #fff;
}

[class^="s-alert-effect-"].s-alert-hide,
[class*=" s-alert-effect-"].s-alert-hide {
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

/* height measurement helper */
.s-alert-box-height {
    visibility: hidden;
    position: fixed;
}
