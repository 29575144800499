button#showCalc {
    border: none;
    width: 100%;
    max-width: 320px;
    padding: 0.5rem 3rem;
    font-size: 1.2rem;
    background-color: #fff;
    color: $link-color;

    img {
        width: 100%;
    }
}
/*
div.carousel-inner div.carousel-item {
    text-align: center;
}
div.carousel-inner div.carousel-item img {
    margin: 0 auto;
    margin-bottom: 100px;
}
a.carousel-control.left, a.carousel-control.right {
    background-image: none;
}
div.carousel-caption {
    font-size: 14pt;
    background-color: #666666;
}
*/